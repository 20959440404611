<script lang="ts">
	import Layout from "../components/Layout.svelte";
	import Link from "../components/Link.svelte";
	import translations from "./NotFound.translations.json";
	import { getTranslate } from "../utils/getTranslate.js";

	const translate = getTranslate(translations);
</script>

<Layout>
	<section class="container pt-20 md:mt-32">
		<h1 class="text-center">{translate("title")}</h1>
		<div class="mt-8 text-center">
			<p>{translate("content")}</p>
			<p>
				{translate("makeSure")}
			</p>
			<div class="mx-auto flex w-min justify-center py-12">
				<Link asButton href="/" reversed class="max-2xl:!px-4">
					{translate("back")}
				</Link>
			</div>
		</div>
	</section>
</Layout>
